// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["fruaocsCg", "JaKaYwHLq"];

const variantClassNames = {fruaocsCg: "framer-v-a9ocvc", JaKaYwHLq: "framer-v-19hkker"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "fruaocsCg", "Variant 2": "JaKaYwHLq"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "fruaocsCg", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "fruaocsCg", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1bpd150 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("JaKaYwHLq"), 400)
})

useOnVariantChange(baseVariant, {default: onAppear1bpd150})

const isDisplayed = () => {
if (baseVariant === "JaKaYwHLq") return true
return false
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-B6Jy6", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-a9ocvc", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"fruaocsCg"} ref={ref} style={{backgroundColor: "rgba(203, 1, 255, 0.15)", ...style}} transition={transition} {...addPropertyOverrides({JaKaYwHLq: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-1hm016p"} layoutDependency={layoutDependency} layoutId={"F9k1helNz"} style={{backgroundColor: "rgb(203, 1, 255)"}} transition={transition}/>)}{isDisplayed() && (<motion.div className={"framer-1kic98c"} layoutDependency={layoutDependency} layoutId={"eCeN9BxRM"} style={{backgroundColor: "rgb(203, 1, 255)", borderBottomLeftRadius: 13, borderBottomRightRadius: 13, borderTopLeftRadius: 13, borderTopRightRadius: 13}} transition={transition}/>)}{isDisplayed() && (<motion.div className={"framer-kctwid"} layoutDependency={layoutDependency} layoutId={"b_L3wLV54"} style={{backgroundColor: "rgb(203, 1, 255)"}} transition={transition}/>)}{isDisplayed() && (<motion.div className={"framer-szj8mo"} layoutDependency={layoutDependency} layoutId={"eyqgLmQDb"} style={{backgroundColor: "rgb(203, 1, 255)", borderBottomLeftRadius: 13, borderBottomRightRadius: 13, borderTopLeftRadius: 13, borderTopRightRadius: 13}} transition={transition}/>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-B6Jy6 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-B6Jy6 * { box-sizing: border-box; }", ".framer-B6Jy6 .framer-5whp8a { display: block; }", ".framer-B6Jy6 .framer-a9ocvc { height: 92px; overflow: hidden; position: relative; width: 631px; }", ".framer-B6Jy6 .framer-1hm016p { bottom: -2px; flex: none; height: 90px; left: 0px; overflow: visible; position: absolute; width: 7px; z-index: 1; }", ".framer-B6Jy6 .framer-1kic98c { flex: none; height: 25px; left: -9px; overflow: visible; position: absolute; top: -9px; width: 25px; z-index: 1; }", ".framer-B6Jy6 .framer-kctwid { flex: none; height: 90px; overflow: visible; position: absolute; right: 0px; top: 0px; width: 7px; z-index: 1; }", ".framer-B6Jy6 .framer-szj8mo { bottom: -12px; flex: none; height: 25px; overflow: visible; position: absolute; right: -9px; width: 25px; z-index: 1; }", ".framer-B6Jy6.framer-v-19hkker .framer-a9ocvc { overflow: visible; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 92
 * @framerIntrinsicWidth 631
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JaKaYwHLq":{"layout":["fixed","fixed"]}}}
 */
const FramermZqyYdm5A: React.ComponentType<Props> = withCSS(Component, css, "framer-B6Jy6") as typeof Component;
export default FramermZqyYdm5A;

FramermZqyYdm5A.displayName = "highlight-animation-1";

FramermZqyYdm5A.defaultProps = {height: 92, width: 631};

addPropertyControls(FramermZqyYdm5A, {variant: {options: ["fruaocsCg", "JaKaYwHLq"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramermZqyYdm5A, [])